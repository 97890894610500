import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55207cc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "register-header" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "login-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/logo/digitalsign-logo-black.svg')
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("footer", null, _toDisplayString(_ctx.$t("sidebarAdmin.copyright")), 1)
  ]))
}